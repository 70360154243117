import { Injectable, Inject, EventEmitter } from '@angular/core';
import { THEMES, ACTIVE_THEME, Theme } from './model';
import {DOCUMENT} from '@angular/common';
import {ThemeService} from '../../../../../src/app/theme/service';
import {AppEnvService} from '../../../../../src/app/config/env/service';
import {ActivatedRoute} from '@angular/router';
import {ThemeActions} from '../../../../../src/app/theme/store/actions';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class DasThemeService extends ThemeService {

  themeChange = new EventEmitter<Theme>();

  constructor(
     env: AppEnvService,
     route: ActivatedRoute,
     actions: ThemeActions,
     http: HttpClient,
    @Inject(THEMES) public themes: Theme[],
    @Inject(ACTIVE_THEME) public theme: string,
    @Inject(DOCUMENT) private document: Document
  ) {
    super(env, route, actions, http);
  }

  getTheme(name: string) {
    const theme = this.themes.find(t => t.name === name);
    if (!theme) {
      throw new Error(`Theme not found: '${name}'`);
    }
    return theme;
  }

  getActiveTheme() {
    return this.getTheme(this.theme);
  }

  getProperty(propName: string) {
    return this.getActiveTheme().properties[propName];
  }

  setTheme(name: string) {
    this.theme = name;
    this.themeChange.emit( this.getActiveTheme());
  }

  registerTheme(theme: Theme) {
    this.themes.push(theme);
  }

  updateTheme(name: string, properties: { [key: string]: string; }) {
    const theme = this.getTheme(name);
    theme.properties = {
      ...theme.properties,
      ...properties
    };

    if (name === this.theme) {
      this.themeChange.emit(theme);
    }
  }
  loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];

    const themeLink = this.document.getElementById(
      'client-theme'
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleName;
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = `${styleName}`;

      head.appendChild(style);
    }
  }

}
