import {defaultEnvironment} from '@environment/environment.defaults';
import {AppEnvConfig} from '@shared/app/config/env/service';

export const environment: AppEnvConfig = {
  ...defaultEnvironment,
  production: true,
  i18n: {
    defaultLanguageCode: 'it-IT',
    langCodeSelection: ['das']
  },
  theme: {
    ...defaultEnvironment.theme,
    defaultTheme: 'das',
    mappedThemes: {
      'das': 'das'
    }
  }
};
