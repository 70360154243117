import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppComponent} from '../../../../src/app/component';
import {BootActions} from '../../../../src/app/boot/store/actions';
import {UserActions} from '../../../../src/app/user/store/actions';
import {AuthActions} from '../../../../src/app/authentication/store/actions';
import {TranslateService} from '@ngx-translate/core';
import {LocalStorageService} from '../../../../src/app/storage/local/service';
import {ActivationEnd, Router} from '@angular/router';
import {AppEnvService} from '../../../../src/app/config/env/service';
import {I18NService} from '../../../../src/app/i18n/service';
import {SepModalService} from '../../../../src/app/common/_services/modal.service';
import {select} from '@angular-redux/store';
import {Observable} from 'rxjs';
import {AuthState} from '../../../../src/app/authentication/store/reducers';
import {UserState} from '../../../../src/app/user/model';
import {filter, map} from 'rxjs/operators';
import {Location} from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './component.html',
  styleUrls: ['./component.scss']
})
export class DasAppComponent extends AppComponent implements OnInit, OnDestroy {
  @select('auth')
  readonly authState: Observable<AuthState>;

  @select('user')
  readonly user: Observable<UserState>;

  showHeader: boolean;

  constructor(
    bootActions: BootActions,
    authActions: AuthActions,
    userActions: UserActions,
    translations: TranslateService,
    localStorage: LocalStorageService,
    environment: AppEnvService,
    i18nService: I18NService,
    private _router: Router,
    modalService: SepModalService,
    location: Location) {
    super(bootActions, authActions, userActions, translations, localStorage, environment, i18nService, _router, modalService, location);

    this._router.events
      .pipe(filter(event => event instanceof ActivationEnd))
      .pipe(filter((event: ActivationEnd) => {
        return event.snapshot.firstChild === null;
      }))
      .pipe(map((event: ActivationEnd) => {
          return event.snapshot;
        })
      ).subscribe(snapshot => {
      this.showHeader = snapshot.data.showHeader !== undefined ? snapshot.data.showHeader : true;
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
