import {environment} from '../../../src/environments/environment';
import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {IcgebAppModule} from './app/module';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(IcgebAppModule, {preserveWhitespaces: true})
  .catch(err => console.error(err));
