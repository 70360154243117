import {NgModule} from '@angular/core';
import {LmsModule} from '../../../../../src/app/lms/module';
import {CommonModule} from '@angular/common';
import {DasLayoutModule} from '../layout/das-layout.module';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    LmsModule,
    DasLayoutModule,
    RouterModule
  ],
  providers: [],
  bootstrap: []
})
export class DasLmsModule {
}
